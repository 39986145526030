import React, { FC } from 'react';
import { Wrapper, List, ListItem, Copy } from './Footer.styled';
import Link from 'next/link';
import { Grid } from '../Grid';
import { default as LinkedIn } from 'public/logo-linkedin.svg';
import { IReferenceLink } from '@src/models/IReferenceLink';
import parseHtml from 'html-react-parser';
import { ISingleLink } from '@src/models/ISingleLink.model';

interface FooterProps {
  footerBottomText: string;
  footerBottomBottomLinks: IReferenceLink[];
  linkedinLink: ISingleLink;
}

export const Footer: FC<FooterProps> = ({
  footerBottomText,
  linkedinLink,
  footerBottomBottomLinks,
}) => {
  return (
    <Grid size="l">
      <Wrapper>
        <Copy>{parseHtml(footerBottomText || '')}</Copy>
        <List>
          {footerBottomBottomLinks.map(linkData => (
            <ListItem key={linkData.contentName}>
              <Link href={linkData.contentSegmentUrl || ''}>
                {linkData.contentName}
              </Link>
            </ListItem>
          ))}
          {linkedinLink && Boolean(linkedinLink.url) && (
            <ListItem>
              <a
                href={linkedinLink.url}
                aria-label="linkedin"
                target={linkedinLink.target}
              >
                <LinkedIn />
              </a>
            </ListItem>
          )}
        </List>
      </Wrapper>
    </Grid>
  );
};
