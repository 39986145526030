import React, { FC, useMemo } from 'react';
import {
  Nav,
  List,
  ListItem,
  SubMenu,
  StyledLink,
  StyledChildLink,
} from './Menu.styled';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Logo } from '../Logo';
import { IHamburgerMenuItem } from '@src/models/IHamburgerMenuItem';
import { ILeftMenu } from '@src/models/ILeftMenu';
import { getLeftMenuData } from '@src/utils/getLeftMenuData';
import { removeEndSlash } from '@src/utils/remove-end-slash';

interface MenuProps {
  hamburgerMenus: IHamburgerMenuItem[];
  enableLogo?: boolean;
  logoSrc?: string;
}

export const Menu: FC<MenuProps> = ({
  hamburgerMenus,
  enableLogo,
  logoSrc,
}) => {
  const router = useRouter();

  const menuData: ILeftMenu[] = useMemo(() => {
    return getLeftMenuData(hamburgerMenus);
  }, [hamburgerMenus]);
  return (
    <Nav>
      {enableLogo && <Logo logoSrc={logoSrc} />}
      <List>
        <ListItem active={router.asPath === '/'}>
          <Link href="/" passHref>
            <StyledLink active={router.asPath === '/'}>Home</StyledLink>
          </Link>
        </ListItem>
        {menuData.map(menuData => (
          <ListItem active={menuData.isActive} key={menuData.name}>
            <Link href={menuData.urlSegment} passHref>
              <StyledLink active={menuData.isActive}>
                {menuData.name}
              </StyledLink>
            </Link>
            {menuData?.childPages.length > 0 &&
              router.asPath.includes(removeEndSlash(menuData.urlSegment)) && (
                <SubMenu>
                  <ul>
                    {menuData?.childPages.map(childPage => (
                      <li key={childPage.contentSegmentUrl}>
                        <Link href={childPage.contentSegmentUrl} passHref>
                          <StyledChildLink active={childPage.isActive}>
                            {childPage.contentName}
                          </StyledChildLink>
                        </Link>
                      </li>
                    ))}
                  </ul>
                </SubMenu>
              )}
          </ListItem>
        ))}
      </List>
    </Nav>
  );
};
