import React from 'react';
import parseHTML from 'html-react-parser';
import get from 'lodash/get';

import styles from './style.module.scss';

import { HTML_DIV_CSS } from 'src/const/config';
import classNames from 'classnames';

interface HTMLRenderProps {
  htmlContent: string;
}

const HTMLRender = (props: HTMLRenderProps) => {
  return (
    <div className={classNames(HTML_DIV_CSS, styles.htmlContainer)}>
      {parseHTML(get(props, 'htmlContent', '') || '')}
    </div>
  );
};

export default HTMLRender;
