import { ILeftMenu } from '@src/models/ILeftMenu';
import React, { useState } from 'react';
import {
  ListItem,
  SubMenu,
  StyledLink,
  StyledChildLink,
  StyledArrow,
} from './MobileMenu.styled';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { removeEndSlash } from '@src/utils/remove-end-slash';

interface MobileMenuItemProps {
  menu: ILeftMenu;
  closeMobileMenu: any;
}

const MobileMenuItem = (props: MobileMenuItemProps) => {
  const router = useRouter();
  const [isOpenSubmenu, setOpenSubmenu] = useState(false);
  const { menu, closeMobileMenu } = props;

  return (
    <ListItem key={menu.urlSegment}>
      <div>
        <Link href={menu.urlSegment} passHref>
          <StyledLink active={menu.isActive} onClick={closeMobileMenu}>
            {menu.name}
          </StyledLink>
        </Link>

        {menu.childPages && (
          <SubMenu
            active={
              router.asPath.includes(removeEndSlash(menu.urlSegment)) ||
              isOpenSubmenu
            }
          >
            {menu.childPages.map(childPage => {
              return (
                <li key={childPage.contentSegmentUrl}>
                  <Link href={childPage.contentSegmentUrl} passHref>
                    <StyledChildLink
                      active={childPage.isActive}
                      onClick={closeMobileMenu}
                    >
                      {childPage.contentName}
                    </StyledChildLink>
                  </Link>
                </li>
              );
            })}
          </SubMenu>
        )}
      </div>
      {Boolean(menu.childPages.length) &&
        !router.asPath.includes(removeEndSlash(menu.urlSegment)) && (
          <StyledArrow
            onClick={() => setOpenSubmenu(!isOpenSubmenu)}
            active={isOpenSubmenu.toString()}
          />
        )}
    </ListItem>
  );
};

export default MobileMenuItem;
